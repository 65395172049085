@import '~@studyportals/styles-abstracts/abstracts';
@import '~@studyportals/styles-abstracts/mixins';

#ScheduledItemsOverview {
	max-width: 53rem;

	.SchedulingOverviewDescription {
		@include TextStyle(Note);
	}

	th,
	td {
		border: 1px solid $GreyL;

		& + th,
		& + td {
			border-left: 0;
		}
	}

	tr + tr {

		th,
		td {
			border-top: 0;
			vertical-align: top;
		}
	}

	table {
		width: 100%;
		text-align: left;
	}

	th,
	td {
		@include PaddingAreas(0.5);
		width: 15%;

		&.WideCell {
			width: 40%;
		}
	}

	td {
		@include TextStyle(Note);

		.SchedulerIcon {
			@include TextStyle(Title100);

			&.Valid {
				color: $Green;
			}

			&.Invalid {
				color: $Red;
			}
		}
	}

	th {
		@include TextStyle(Title100);
	}

	.InvalidReasonsList {
		@include PaddingAreas(0, 0, 0, 1);
		@include MarginAreas(0);
		list-style-type: disc;
	}
}
